<template>
  <div class="content">
    <a-affix :offset-top="0">
      <Header title="订单详情" />
    </a-affix>

    <div class="part-content">
      <div class="part">
        <h1>
          <span v-if="store">{{ store.title }}</span>
          <span v-else>五味楼酒店</span>
        </h1>
        <div class="address">{{ store.address }}</div>

        <div class="actions">
          <a :href="'tel:' + store.phone" class="action">
            <icon-font type="icon-dianhua" />
          </a>

          <div class="action" @click="onNavigation">
            <icon-font type="icon-daohang1" />
          </div>
        </div>
      </div>

      <div class="part">
        <EInfo title="房间" :col="7" :value="order.tableName" />
        <EInfo title="就餐时间" :col="7" :value="order.bookTime" />
        <EInfo title="餐标" :col="7" :value="order.comboPrice" />
        <EInfo title="人数" :col="7" :value="order.peopleCount" />
        <EInfo
          title="备注"
          :col="7"
          v-if="order.customerRemark"
          :value="order.customerRemark"
        />
      </div>

      <div class="part">
        <EInfo title="套餐" :col="7" :value="order.comboName" />
        <div v-for="(v, k) in orderItems" :key="k" class="dish-item">
          <span>
            <a-tag color="green" v-if="v.state === 0">加菜</a-tag>
            <a-tag color="red" v-if="v.state === 1">赠菜</a-tag>
            {{ v.dish.name }}

          </span>

          <span class="dish-price">¥{{v.sellingPrice}} x{{ v.quantity }}</span>
        </div>
      </div>

      <div class="part">
        <EInfo title="订单金额" :col="7" :value="'¥' + order.orderAmount" />
        <EInfo title="支付金额" :col="7" :value="'¥' + order.payAmount" />

        <div :class="'status stu-' + order.orderStatus">
          <span v-if="order.orderStatus <= 3">已预订</span>
          <span v-if="order.orderStatus === 4">已开餐</span>
          <span v-if="order.orderStatus === 5">已退订</span>
          <span v-if="order.orderStatus === 6">未到</span>
          <span v-if="order.orderStatus === 7">已结算</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Header from './Header'
import { OrderClass } from '@/apis/order'
import defaultConfig from '@/config/default.config'
import EInfo from '@/components/customer/form/Info'
import { isIos, wechatConfig } from '@/utils'
import storage from 'store'

const api = new OrderClass()
export default defineComponent({
  components: {
    Header,
    EInfo
  },
  setup () {
    const order = ref({})
    const store = ref({})
    const orderItems = ref([])

    const onGetOrderDetail = (id) => {
      api.detail(id).then((resp) => {
        order.value = resp

        // 加菜或赠菜
        orderItems.value = resp.orderItems.filter((x) => x.type === 4)
      })
    }

    const onNavigation = () => {
      // eslint-disable-next-line no-undef
      wx.openLocation({
        latitude: store.value.lat, // 纬度，浮点数，范围为90 ~ -90
        longitude: store.value.lng, // 经度，浮点数，范围为180 ~ -180。
        name: store.value.title, // 位置名
        address: store.value.address, // 地址详情说明
        scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
      })
    }

    return {
      order,
      store,
      orderItems,
      onGetOrderDetail,
      onNavigation
    }
  },

  created () {
    window.onbeforeunload = (e) => {
      console.log('重新加载', defaultConfig.host)
      const url = defaultConfig.host + this.$route.path
      storage.set('wxConfigURL', url)
    }

    const id = this.$route.params.id
    this.onGetOrderDetail(id)

    this.getStoreInfo()

    if (!isIos()) {
      wechatConfig()
    } else {
      const url = storage.get('wxConfigURL')
      if (url.indexOf('login') === -1 && url.indexOf('regist') === -1) {
        wechatConfig(url)
      }
    }
  },

  methods: {
    async getStoreInfo () {
      const store = this.$store.state.user.storeInfo.id
        ? this.$store.state.user.storeInfo
        : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #f1f1f1;
  .part-content {
    padding: 0.2rem;
    .part {
      box-shadow: 0 0 0.1rem rgba(153, 153, 153, 0.3);
      margin-bottom: 0.3rem;
      padding: 0.3rem 0.2rem;
      position: relative;
      background: #fff;
      border-radius: 5px;

      h1 {
        font-size: 0.4rem;
        line-height: 0.8rem;
        margin: 0;
      }
      .address {
        font-size: 0.2rem;
      }
      .actions {
        position: absolute;
        top: 0.3rem;
        right: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.8rem;
        .action {
          border: 1px solid #ceb175;
          border-radius: 50%;
          width: 0.5rem;
          height: 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ceb175;
          font-size: 0.3rem;
          margin-left: 0.2rem;
        }
      }
      .status {
        position: absolute;
        bottom: 0.2rem;
        right: 0.2rem;
        width: 1.5rem;
        height: 1.5rem;
        border: 1px solid #000;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(-20deg);
        padding: 0.1rem;
        span {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.3rem;
          color: #333;
          font-weight: 800;
          border: 1px solid #000;
          border-radius: 50%;
        }
        &.stu-0,
        &.stu-1,
        &.stu-2,
        &.stu-3 {
          border-color: #fcd63d;
          span {
            border-color: #fcd63d;
            color: #fcd63d;
          }
        }
        &.stu-4 {
          border-color: #ff4f4f;
          span {
            border-color: #ff4f4f;
            color: #ff4f4f;
          }
        }
        &.stu-5 {
          border-color: #f50;
          span {
            border-color: #f50;
            color: #f50;
          }
        }
        &.stu-6 {
          border-color: #ccc;
          span {
            border-color: #ccc;
            color: #ccc;
          }
        }
        &.stu-7 {
          border-color: #87d068;
          span {
            border-color: #87d068;
            color: #87d068;
          }
        }
      }
    }
  }
}

.dish-item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 0.3rem;
}
</style>
